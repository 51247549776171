
import {
    defineComponent, Prop, reactive, ref, PropType
} from 'vue';

interface CardType {
    title: string;
    enable: boolean;
    icon: string;
    type?: string;
}
export default defineComponent({
    props: {
        cardData: {
            type: Object as PropType<CardType>,
            required: true
        },
        text: {
            type: [String, Number]
        }
    },
    setup(props) {
        const cardMsg: CardType = reactive({ ...props.cardData });

        return {
            cardMsg
        };
    }
});
